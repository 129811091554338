// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-additive-fertigung-js": () => import("./../../../src/pages/additive-Fertigung.js" /* webpackChunkName: "component---src-pages-additive-fertigung-js" */),
  "component---src-pages-dienstleistungen-dienstleistungen-js": () => import("./../../../src/pages/Dienstleistungen/dienstleistungen.js" /* webpackChunkName: "component---src-pages-dienstleistungen-dienstleistungen-js" */),
  "component---src-pages-drucker-printer-js": () => import("./../../../src/pages/Drucker/printer.js" /* webpackChunkName: "component---src-pages-drucker-printer-js" */),
  "component---src-pages-engineering-leistungen-js": () => import("./../../../src/pages/engineering-leistungen.js" /* webpackChunkName: "component---src-pages-engineering-leistungen-js" */),
  "component---src-pages-fibreshape-messsystem-fibreshape-js": () => import("./../../../src/pages/Fibreshape/Messsystem-Fibreshape.js" /* webpackChunkName: "component---src-pages-fibreshape-messsystem-fibreshape-js" */),
  "component---src-pages-impressum-datenschutzbestimmungen-js": () => import("./../../../src/pages/Impressum/datenschutzbestimmungen.js" /* webpackChunkName: "component---src-pages-impressum-datenschutzbestimmungen-js" */),
  "component---src-pages-impressum-impressum-js": () => import("./../../../src/pages/Impressum/impressum.js" /* webpackChunkName: "component---src-pages-impressum-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materialien-materialien-filamente-js": () => import("./../../../src/pages/Materialien/Materialien-Filamente.js" /* webpackChunkName: "component---src-pages-materialien-materialien-filamente-js" */),
  "component---src-pages-materialien-materialien-js": () => import("./../../../src/pages/Materialien/Materialien.js" /* webpackChunkName: "component---src-pages-materialien-materialien-js" */),
  "component---src-pages-materialien-materialien-sla-js": () => import("./../../../src/pages/Materialien/Materialien-SLA.js" /* webpackChunkName: "component---src-pages-materialien-materialien-sla-js" */),
  "component---src-pages-materialien-materialien-sls-js": () => import("./../../../src/pages/Materialien/Materialien-SLS.js" /* webpackChunkName: "component---src-pages-materialien-materialien-sls-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-projekte-projekt-page-js": () => import("./../../../src/pages/Projekte/ProjektPage.js" /* webpackChunkName: "component---src-pages-projekte-projekt-page-js" */),
  "component---src-pages-veranstaltungen-va-191120-js": () => import("./../../../src/pages/Veranstaltungen/Va-191120.js" /* webpackChunkName: "component---src-pages-veranstaltungen-va-191120-js" */),
  "component---src-pages-veranstaltungen-va-keine-js": () => import("./../../../src/pages/Veranstaltungen/Va-keine.js" /* webpackChunkName: "component---src-pages-veranstaltungen-va-keine-js" */)
}

